import React, { FC, useState, ChangeEvent, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import useGetProject from "utils/hooks/useGetProject";
import Grid from "components/Grid";
import Typography from "components/Typography";
import Table from "components/Table";
import Button from "components/Button";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Close";
import { notifySuccess, notifyError } from "utils/notify";
import Switch from "components/Switch";
import useIsDesktop from "utils/hooks/useIsDesktop";
import { GET_BANK_SLIP_RECEIVERS } from "graphql/autoVerifySlip/query";
import {
  UPDATE_BANK_SLIP_RECEIVER,
  DELETE_BANK_SLIP_RECEIVER,
  UPDATE_AUTO_VERIFY_SLIP_ENABLED,
} from "graphql/autoVerifySlip/mutation";
import { ProjectIdType } from "types/Project";
import COLORS from "constants/Colors";
import { Column } from "react-table";
import { PageWrapper, CustomTableWrapper, ActionButton, Status, Item } from "./styled";

interface BankSlipReceiverData {
  id: string;
  status: string;
  accountNumber: string;
  name: string;
  createdAt: string;
}

const VerificationSlip: FC = () => {
  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();
  const isDesktop = useIsDesktop();
  const { isAutoVerifySlipEnabled } = useGetProject(projectId);
  const [isAutoVerifyEnabled, setIsAutoVerifyEnabled] = useState(isAutoVerifySlipEnabled);

  const { data, loading } = useQuery(GET_BANK_SLIP_RECEIVERS, {
    skip: !projectId,
    variables: {
      projectId,
    },
  });

  const [updateReceiver] = useMutation(UPDATE_BANK_SLIP_RECEIVER, {
    onCompleted: () => notifySuccess(t("Update successfully")),
    onError: () => notifyError(t("Update failed")),
    refetchQueries: [
      {
        query: GET_BANK_SLIP_RECEIVERS,
        variables: { projectId },
      },
    ],
  });
  
  const [deleteReceiver] = useMutation(DELETE_BANK_SLIP_RECEIVER, {
    onCompleted: () => notifySuccess(t("Update successfully")), 
    onError: () => notifyError(t("Update failed")),
    refetchQueries: [
      {
        query: GET_BANK_SLIP_RECEIVERS,
        variables: { projectId },
      },
    ],
  });

  const [updateAutoVerifySlipConfiguration] = useMutation(UPDATE_AUTO_VERIFY_SLIP_ENABLED, {
    onError: () => {
      setIsAutoVerifyEnabled((prev: boolean) => !prev);
      notifyError(t("Update failed!"));
    },
    onCompleted: () => notifySuccess(t("Update successfully")),
    refetchQueries: [
      {
        query: GET_BANK_SLIP_RECEIVERS,
        variables: { projectId },
      },
    ],
  });

  const handleToggleActive = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIsAutoVerifyEnabled(checked);
    updateAutoVerifySlipConfiguration({
      variables: { projectId, isActive: checked },
    });
  };

  const filterReceiversByStatus = (status: string) => {
    return data?.bankSlipReceivers.filter((receiver: BankSlipReceiverData) => receiver.status === status) || [];
  };

  const newReceivers = filterReceiversByStatus("New");
  const approvedReceivers = filterReceiversByStatus("Approved");

  const handleApprove = useCallback(
    (receiverId: string) => {
      updateReceiver({ variables: { projectId, receiverId, status: "Approved" } });
    },
    [projectId, updateReceiver],
  );

  const handleDelete = useCallback(
    (receiverId: string) => {
      deleteReceiver({ variables: { projectId, receiverId } });
    },
    [projectId, deleteReceiver],
  );

  const formatDate = (dateString: string) => new Date(dateString).toLocaleString();

  const renderCell = (value: string, color = COLORS.DarkGray) => (
    <Typography variant="body4" color={color}>
      {value}
    </Typography>
  );

  const columns = useMemo<Column<BankSlipReceiverData>[]>(
    () => [
      {
        id: "accountNumber",
        Header: String(t("Account number")),
        accessor: "accountNumber",
        Cell: ({ value }) => renderCell(value),
      },
      {
        id: "name",
        Header: String(t("Account name")),
        accessor: "name",
        Cell: ({ value }) => renderCell(value),
      },
      {
        id: "status",
        Header: String(t("status")),
        accessor: "status",
        Cell: ({ value }) => (
          <Status color={COLORS.DarkGray}>
            {value === "New"
              ? t("deeplePay.autoVerification.table.header.new")
              : t("deeplePay.autoVerification.table.action.approve")}
          </Status>
        ),
      },
      {
        id: "createdAt",
        Header: String(t("dateTime")),
        accessor: "createdAt",
        Cell: ({ value }) => renderCell(formatDate(value)),
      },
      {
        id: "action",
        Header: String(t("deeplePay.autoVerification.table.action.approve")),
        accessor: "id",
        Cell: ({ value, row }) => (
          <ActionButton>
            {row.original.status === "New" ? (
              <Button onClick={() => handleApprove(value)} size="small" className="approve-button">
                <ChevronRightIcon fontSize="small" />
              </Button>
            ) : (
              <Button onClick={() => handleDelete(value)} size="small" className="delete-button">
                <CloseIcon fontSize="small" />
              </Button>
            )}
          </ActionButton>
        ),
      },
    ],
    [t, handleApprove, handleDelete],
  );

  if (loading) {
    return null;
  }

  return (
    <PageWrapper container alignContent="flex-start" className={isDesktop ? "" : "mobile"}>
      <Grid item xs={12} className={!isDesktop ? "pl-3 py-4" : ""}>
        <Typography variant="title2">{t("deeplePay.header.verificationSlip")}</Typography>
      </Grid>

      <Item>
        <Grid container alignItems="center" className="mb-4">
          <Grid item>
            <Switch checked={isAutoVerifyEnabled} onChange={handleToggleActive} />
          </Grid>
          <Grid item className="ml-2">
            <Typography variant="body3">{isAutoVerifyEnabled ? t("Active") : t("Disabled")}</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="title3" className="mb-3">
              {t("deeplePay.autoVerification.table.header.new")}
            </Typography>
            <CustomTableWrapper>
              <Table<BankSlipReceiverData, Record<string, unknown>> data={newReceivers} columns={columns} />
            </CustomTableWrapper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="title3" className="mb-3">
              {t("deeplePay.autoVerification.table.action.approve")}
            </Typography>
            <CustomTableWrapper>
              <Table<BankSlipReceiverData, Record<string, unknown>>
                data={approvedReceivers}
                columns={[
                  ...columns.slice(0, -1),
                  {
                    ...columns[columns.length - 1],
                    Header: String(t("deeplePay.header.verificationSlip.action.delete")),
                  },
                ]}
              />
            </CustomTableWrapper>
          </Grid>
        </Grid>
      </Item>
    </PageWrapper>
  );
};

export default VerificationSlip;
