import { useQuery, QueryHookOptions } from "@apollo/client";
import get from "lodash/get";
import { PROJECT_INFO } from "graphql/project/query";
import { MultiStoreInventoryType, ProjectType } from "types/Project";
import { IS_ALLOW_NEW_PACKAGE } from "config";
import { PLATFORM } from "types";

export type GetProject = { getProject: Partial<ProjectType> };

const useGetProject = (projectId?: string, options: QueryHookOptions = {}) => {
  const { data, loading, refetch } = useQuery<GetProject>(PROJECT_INFO, {
    skip: !projectId,
    variables: {
      projectId,
    },
    errorPolicy: "ignore",
    ...options,
  });

  const botGender = data?.getProject?.botGender;
  const facebookPageId = get(data, "getProject.facebookPageId");
  const isAddonPackageEnabled = get(data, "getProject.isAddonPackageEnabled");
  const isAllowingConnectMoreChannel = get(data, "getProject.isAllowingConnectMoreChannel") || false;
  const isBroadcastMessageEnabled = get(data, "getProject.isBroadcastMessageEnabled");
  const isCurrentProjectEnterprise = get(data, "getProject.isEnterprise") || false;
  const isCustomThemeConfigurationEnabled = get(data, "getProject.isCustomThemeConfigurationEnabled");
  const isDigitalContentEnabled = get(data, "getProject.configuration.product.digitalContent.isActive") || false;
  const isAutoVerifySlipEnabled = get(data, "getProject.configuration.payment.isAutoVerifySlip.isActive");
  const isDisabled = get(data, "getProject.isDisabled");
  const isFacebookAutoEngagementEnabled = get(data, "getProject.isFacebookAutoEngagementEnabled") || false;
  const isIntegrationEnabled = get(data, "getProject.isIntegrationEnabled") || false;
  const isOfflineOrderEnabled = get(data, "getProject.isOfflineOrderEnabled");
  const isOpenApiConfigurationEnabled = get(data, "getProject.isOpenApiConfigurationEnabled") || false;
  const isPaymentGatewayAddonEnabled = get(data, "getProject.isPaymentGatewayAddonEnabled") || false;
  const isSalesReportEnabled = get(data, "getProject.isSalesReportEnabled");
  const isAdvancedSalesReportEnabled = get(data, "getProject.isAdvancedSalesReportEnabled");
  const isCustomerReportEnabled = get(data, "getProject.isCustomerReportEnabled");
  const isAdvancedCustomerReportEnabled = get(data, "getProject.isAdvancedCustomerReportEnabled");
  const isConsignmentReportEnabled = get(data, "getProject.isConsignmentReportEnabled");
  const isAdvancedConsignmentReportEnabled = get(data, "getProject.isAdvancedConsignmentReportEnabled");
  const isShippopEnabled = get(data, "getProject.configuration.shipping.shippop.isActive") || false;
  const isUsingCustomPackage = get(data, "getProject.isUsingCustomPackage") || false;
  const packageEntityLevel = get(data, "getProject.packageEntityLevel");
  const refId = get(data, "getProject.refId");
  const monthlyBillingPaymentIssueType = get(data, "getProject.monthlyBillingPaymentIssueType");
  const isInitialProjectCompleted = get(data, "getProject.isInitialProjectCompleted");
  const isBankTransferEnabled = get(data, "getProject.isBankTransferEnabled");
  const isDeeplePayEnabled = get(data, "getProject.isDeeplePayEnabled") || false;
  const isAllowApproveBySlip = get(data, "getProject.isAllowApproveBySlip") || false;
  const isGuidelineCompleted = data?.getProject?.isGuidelineCompleted;
  const isUsingNotSupportedPackage = IS_ALLOW_NEW_PACKAGE
    ? get(data, "getProject.isUsingNotSupportedPackage") || false
    : false;
  const isSupportLegacyPayment = get(data, "getProject.isSupportLegacyPayment");
  const availablePackageSubscriptionTypeToUpgrade =
    get(data, "getProject.availablePackageSubscriptionTypeToUpgrade") || [];
  const isFacebookConnected = get(data, "getProject.isFacebookConnected") || false;
  const isInstagramConnected = get(data, "getProject.isInstagramConnected") || false;
  const isLineConnected = get(data, "getProject.isLineConnected") || false;
  const isChatbotEnabled = Boolean(get(data, "getProject.isChatbotEnabled")) || false;
  const instagramBusinessName = get(data, "getProject.instagramBusinessName");
  const isKymApproved = get(data, "getProject.isKymApproved") || false;
  const isUsingDeeplePay = get(data, "getProject.isUsingDeeplePay") || false;
  const multiStoreInventoryType: MultiStoreInventoryType = get(data, "getProject.multiStoreInventoryType");

  const getCredentialConnected = (platform: PLATFORM): boolean => {
    switch (platform) {
      case PLATFORM.FACEBOOK:
        return get(data, "getProject.isFacebookConnected") || false;
      case PLATFORM.INSTAGRAM:
        return get(data, "getProject.isInstagramConnected") || false;
      default:
        return get(data, "getProject.isLineConnected") || false;
    }
  };

  const orderLimitReachedStatus: ProjectType["orderLimitReachedStatus"] = get(
    data,
    "getProject.orderLimitReachedStatus",
  );
  const lineChannelAccount = get(data, "getProject.lineChannelAccount");

  return {
    botGender,
    facebookPageId,
    isAddonPackageEnabled,
    isAdvancedConsignmentReportEnabled,
    isAdvancedCustomerReportEnabled,
    isAdvancedSalesReportEnabled,
    isAllowingConnectMoreChannel,
    isBroadcastMessageEnabled,
    isConsignmentReportEnabled,
    isCurrentProjectEnterprise,
    isCustomerReportEnabled,
    isCustomThemeConfigurationEnabled,
    isDigitalContentEnabled,
    isDisabled,
    isFacebookAutoEngagementEnabled,
    isGuidelineCompleted,
    isIntegrationEnabled,
    isOfflineOrderEnabled,
    isOpenApiConfigurationEnabled,
    isPaymentGatewayAddonEnabled,
    isSalesReportEnabled,
    isShippopEnabled,
    isUsingCustomPackage,
    isUsingDeeplePay,
    isUsingNotSupportedPackage,
    loading,
    packageEntityLevel,
    refId,
    monthlyBillingPaymentIssueType,
    multiStoreInventoryType,
    refetchProject: refetch,
    isInitialProjectCompleted,
    isBankTransferEnabled,
    isAutoVerifySlipEnabled,
    isDeeplePayEnabled,
    isAllowApproveBySlip,
    isSupportLegacyPayment,
    isFacebookConnected,
    isInstagramConnected,
    isLineConnected,
    availablePackageSubscriptionTypeToUpgrade,
    getCredentialConnected,
    isChatbotEnabled,
    instagramBusinessName,
    isKymApproved,
    orderLimitReachedStatus,
    lineChannelAccount,
  };
};

export default useGetProject;
