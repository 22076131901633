import { MIN_PASSWORD, PHONE_NUMBER_LENGTH } from "constants/Auth";

const common = {
  en: {
    "Showing result": "Showing result",
    of: "of",
    "go to page": "go to page",
    "All time": "All time",
    last30days: "Last 30 days",
    last7days: "Last 7 days",
    yesterday: "Yesterday",
    thisWeek: "This week",
    thisMonth: "This month",
    lastWeek: "Last week",
    lastMonth: "Last month",
    "Date range": "Date range",
    Chat: "Chat",
    "Help Center": "Help Center",
    Profile: "Profile",
    Email: "Email",
    Name: "Name",
    Password: "Password",
    PasswordConfirmation: "Passwords must match",
    Register: "Register",
    "Log in": "Log in",
    "login.header": "ลงชื่อเข้าใช้งาน LeanBiz AI Chatbot",
    "register.header": "สมัครใช้งาน LeanBiz AI Chatbot",
    "Forgot password": "Forgot password",
    "Reset Password": "Reset Password",
    "Wait a few seconds.": "Wait a few seconds.",
    "Already have an account?": "Already have an account?",
    "email is a required field": "email is a required field",
    "Invalid email address": "Invalid email address",
    [`Password must be at least ${MIN_PASSWORD} characters.`]: `Password must be at least ${MIN_PASSWORD} characters.`,
    PhoneNumberMatchError: `Phone number must be at least ${PHONE_NUMBER_LENGTH} digits`,
    Project: "Project",
    Search: "Search",
    "Search result": "Search result",
    Filters: "Filters",
    Close: "Close",
    Active: "Active",
    Inactive: "Inactive",
    Disabled: "Disabled",
    Download: "Download",
    "Add Image": "Add Image",
    Save: "Save",
    Saving: "Saving...",
    "no option": "No option",
    "you have reach limit": "You have reach limit",
    Cancel: "Cancel",
    Remove: "Remove",
    Back: "Back",
    "Back to main menu": "Back to main menu",
    Add: "Add",
    "Search not found": "Search not found",
    "Update failed!": "Update failed!",
    "Update successfully": "Update successfully",
    "Save failed!": "Save failed!",
    "Save successfully": "Save successfully",
    "already exist": "Already exist",
    "No option": "No option",
    "No accept special character": "No accept special character",
    Confirm: "Confirm",
    "Add tag": "Add tag",
    Copy: "Copy",
    Ok: "Ok",
    days: "day",
    days_plural: "days",
    week: "week",
    week_plural: "weeks",
    month: "month",
    month_plural: "months",
    Today: "Today",
    Tomorrow: "Tomorrow",
    Update: "Update",
    "Report issue": "Report issue",
    Free: "Free",
    "DisableFeature.title.free": "Please upgrade to standard package",
    "DisableFeature.description1.free": "You are currently subscribing to Free package which disable this feature.",
    "DisableFeature.title.professional": "Please upgrade to advance package",
    "DisableFeature.description1.professional":
      "You are currently subscribing to Standard package which disable this feature.",
    "DisableFeature.button": "Change your package",
    "DisableFeature.upgradeTo": "Upgrade to",
    "DisableFeature.planName.free": "Standard Plan",
    "DisableFeature.planName.professional": "Advance Plan",
    "DisableFeature.start": "Start at",
    "DisableFeature.per": "per",
    "DisableFeature.month": "month",
    Phone: "Phone",
    Help: "Help",
    "Watch a tutorial": "Watch a tutorial",
    "Invalid schedule date": "Invalid schedule date",
    Store: "Store",
    cm: "cm",
    gram: "gram",
    Edit: "Edit",
    Delete: "Delete",
    "Add quick reply message": "Add quick reply message ex. เมนูสินค้า, ติดต่อแอดมิน",
    "Quick reply disabled":
      "‘Quick reply’ feature is only available when the latest message is filled with text, otherwise it will be disabled.",
    LOADING: "Loading",
    NOTIFICATION: "Notification",
    NEW_NOTIFICATION: "new notification",
    ALL_NOTIFICATION: "All notification",
    NO_NOTIFICATION: "No notification",
    SEE_ALL: "See all",
    REFRESH: "Refresh",
    Notifications: "Notifications",
    SEARCH_BY_CUSTOMER_NAME: "Search by customer name",
    MARK_AS_READ: "Mark as read",
    I_ACCEPT: "I accept",
    Yes: "Yes",
    deliveryScheduleDateRange: "Delivery scheduled date range",
    carrierNames: "Carrier names",
    uploading: "Uploading...",
    turnoff: "Turn off",
    dateTime: "Date/time",
  },
  th: {
    "Showing result": "แสดงผลลัพธ์",
    of: "จาก",
    "go to page": "ไปยังหน้า",
    "All time": "ย้อนหลังทุกวัน",
    last30days: "ย้อนหลัง 30 วัน",
    last7days: "ย้อนหลัง 7 วัน",
    yesterday: "ย้อนหลัง 1 วัน",
    thisWeek: "สัปดาห์นี้",
    thisMonth: "เดือนนี้",
    lastWeek: "สัปดาห์ที่แล้ว",
    lastMonth: "เดือนที่แล้ว",
    "Date range": "เลือกระยะเวลา",
    Profile: "ข้อมูลส่วนตัว",
    "Help Center": "ติดต่อสอบถาม",
    Chat: "ห้องสนทนา",
    Email: "อีเมล",
    Name: "ชื่อ",
    Password: "รหัสผ่าน",
    PasswordConfirmation: "ยืนยันรหัสผ่านไม่ถูกต้อง",
    Register: "ลงทะเบียน",
    "Log in": "เข้าสู่ระบบ",
    "login.header": "ลงชื่อเข้าใช้งาน LeanBiz AI Chatbot",
    "register.header": "สมัครใช้งาน LeanBiz AI Chatbot",
    Project: "โปรเจค",
    "Forgot password": "ลืมรหัสผ่าน",
    "Reset Password": "ตั้งค่ารหัสผ่านใหม่",
    "Wait a few seconds.": "กรุณารอสักครู่",
    "Already have an account?": "คุณมีบัญชีผู้ใช้แล้วใช่ไหม?",
    "email is a required field": "กรุณากรอกอีเมล",
    "Invalid email address": "ที่อยู่อีเมลผิด",
    [`Password must be at least ${MIN_PASSWORD} characters.`]: `รหัสผ่าน ต้องมีมากกว่า ${MIN_PASSWORD} ตัวอักษร`,
    PhoneNumberMatchError: `หมายเลขโทรศัพท์ต้องมีจำนวน ${PHONE_NUMBER_LENGTH} หลัก`,
    Search: "ค้นหา",
    "Search result": "ผลการค้นหา",
    Filters: "ตัวกรอง",
    Close: "ปิด",
    Active: "เปิดใช้งาน",
    Inactive: "ปิดใช้งาน",
    Disabled: "ปิดการใช้งาน",
    Download: "ดาวน์โหลด",
    "Add Image": "เพิ่มรูปภาพ",
    Save: "บันทึก",
    Saving: "กำลังบันทึก...",
    "no option": "ไม่มีตัวเลือก",
    "you have reach limit": "ถึงจำนวนจำกัดแล้ว",
    Cancel: "ยกเลิก",
    Remove: "ลบ",
    Back: "กลับ",
    "Back to main menu": "กลับเมนูหลัก",
    Add: "เพิ่ม",
    "Search not found": "ไม่พบข้อมูล",
    "Update failed!": "อัพเดทล้มเหลว!",
    "Update successfully": "บันทึกสำเร็จ",
    "already exist": "คำนี้ใช้ไปแล้ว",
    "No option": "ไม่มีตัวเลือก",
    "No accept special character": "ไม่รับตัวอักษรพิเศษ",
    Confirm: "ยืนยัน",
    "Add tag": "เพิ่มคำ",
    Copy: "คัดลอก",
    Ok: "ตกลง",
    days: "วัน",
    days_plural: "วัน",
    week: "สัปดาห์",
    week_plural: "สัปดาห์",
    month: "เดือน",
    month_plural: "เดือน",
    Today: "วันนี้",
    Tomorrow: "พรุ่งนี้",
    Update: "อัพเดท",
    "Save failed!": "บันทึกล้มเหลว",
    "Save successfully": "บันทึกสำเร็จ",
    "Report issue": "รายงานปัญหา",
    Free: "ฟรี",
    "DisableFeature.title.free": "กรุณาอัพเกรดแพ็กเกจการใช้งาน",
    "DisableFeature.description1.free": "ปัจจุบันคุณกำลังใช้งานแพ็กเกจฟรี ซึ่งไม่สามารถใช้งานฟีเจอร์ดังกล่าวได้",
    "DisableFeature.title.professional": "กรุณาอัพเกรดแพ็กเกจการใช้งาน",
    "DisableFeature.description1.professional":
      "ปัจจุบันคุณกำลังใช้งานแพ็กเกจสแตนดาร์ด ซึ่งไม่สามารถใช้งานฟีเจอร์ดังกล่าวได้",
    "DisableFeature.button": "เปลี่ยนแพ็กเกจการใช้งาน",
    "DisableFeature.upgradeTo": "อัพเกรดไปยัง",
    "DisableFeature.planName.free": "สแตนดาร์ด แพ็กเกจ",
    "DisableFeature.planName.professional": "แอดวานซ์ แพ็กเกจ",
    "DisableFeature.start": "ราคาเริ่มต้น",
    "DisableFeature.per": "ต่อ",
    "DisableFeature.month": "เดือน",
    Phone: "หมายเลขโทรศัพท์",
    Help: "ช่วยเหลือ",
    "Watch a tutorial": "ดูวิดีโอการตั้งค่า",
    "Invalid schedule date": "ไม่สามารถจัดส่งในช่วงเวลาดังกล่าวได้",
    Store: "ร้านค้า",
    cm: "ซม.",
    gram: "กรัม",
    Edit: "แก้ไข",
    Delete: "ลบ",
    "Add quick reply message": "เพิ่มข้อความ quick reply เช่น เมนูสินค้า, ติดต่อแอดมิน",
    "Quick reply disabled":
      "ข้อความ ‘Quick reply’ จะใช้ได้เมื่อข้อความตอบกลับอันสุดท้ายเป็นข้อความเท่านั้น ไม่อย่างนั้นจะไม่สามารถใช้งานได้",
    LOADING: "กำลังโหลด",
    NOTIFICATION: "การแจ้งเตือน",
    NEW_NOTIFICATION: "การแจ้งเตือนใหม่",
    ALL_NOTIFICATION: "การแจ้งเตือนทั้งหมด",
    NO_NOTIFICATION: "ไม่พบการแจ้งเตือน",
    SEE_ALL: "ดูการแจ้งเตือนทั้งหมด",
    REFRESH: "รีเฟรช",
    Notifications: "การแจ้งเตือน",
    SEARCH_BY_CUSTOMER_NAME: "ค้นหาด้วยชื่อลูกค้า",
    MARK_AS_READ: "กดว่าอ่านแล้ว",
    I_ACCEPT: "ฉันยินยอม",
    Yes: "ใช่",
    deliveryScheduleDateRange: "ช่วงเวลากำหนดการจัดส่ง",
    carrierNames: "บริษัทส่งพัสดุ",
    uploading: "กำลังอัปโหลด...",
    turnoff: "ปิดการใช้งาน",
    dateTime: "วันที่/เวลา",
  },
};

export default common;
