import { gql } from "@apollo/client";

export const UPDATE_BANK_SLIP_RECEIVER = gql`
  mutation updateBankSlipReceiver($projectId: ID!, $receiverId: ID!, $status: String!) {
    updateBankSlipReceiver(projectId: $projectId, receiverId: $receiverId, status: $status) {
      id
      name
      accountNumber
      bank
      status
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_BANK_SLIP_RECEIVER = gql`
  mutation deleteBankSlipReceiver($projectId: ID!, $receiverId: ID!) {
    deleteBankSlipReceiver(projectId: $projectId, receiverId: $receiverId) {
      id
    }
  }
`;

export const UPDATE_AUTO_VERIFY_SLIP_ENABLED = gql`
  mutation updateAutoVerifySlipConfiguration($projectId: ID!, $isActive: Boolean!) {
    updateAutoVerifySlipConfiguration(projectId: $projectId, isActive: $isActive) {
      configuration {
        payment {
          isAutoVerifySlip {
            isActive
          }
        }
      }
    }
  }
`;
