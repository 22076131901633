import { gql } from "@apollo/client";

export const GET_BANK_SLIP_RECEIVERS = gql`
  query getBankSlipReceivers($projectId: ID!) {
    bankSlipReceivers(projectId: $projectId) {
      id
      name
      accountNumber
      bank
      status
      createdAt
      updatedAt
    }
  }
`;
