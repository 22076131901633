import styled from "styled-components";
import { VARIANTS } from "constants/Typography";
import Grid from "components/Grid";

export const PageWrapper = styled(Grid)`
  padding: 48px 48px 120px;
  width: 100%;

  &.mobile {
    padding: 0 0 96px;
  }
`;

export const Item = styled.div`
  background-color: ${({ theme }) => theme.COLORS.White};
  border: 1px solid rgba(202, 210, 228, 0.5);
  border-radius: 8px;
  position: relative;
  font-size: ${VARIANTS.body4.size};
  overflow: auto;
  flex-direction: column;
  padding: 24px;
  width: 100%;
`;

export const CustomTableWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 250px;
  max-height: 400px;
  overflow: auto;
  overflow-x: scroll;
  line-height: 1rem;
  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    display: initial;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(83, 83, 83, 0.5);
  }

  ::-webkit-scrollbar-corner {
    background-color: ${({ theme }) => theme.COLORS.Light};
  }

  .table {
    border-top: 1px solid ${({ theme }) => theme.COLORS.LightBlue} !important;
    font-size: ${VARIANTS.body4.size};
    display: inline-block;
    width: 100%;
    box-sizing: border-box;

    .th {
      padding: 12px 10px 8px;
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
      background-color: white;
      color: ${({ theme }) => theme.COLORS.DarkGray};
      font-weight: 500;
      word-break: break-word;
      line-height: 20px;
      width: 100%;
    }

    .td {
      width: 100%;
      padding: 12px 10px;
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
      background-color: white;
    }

    .tr {
      width: 100%;
      display: flex;

      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    max-height: none;
  }
`;
export const ActionButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  .approve-button {
    padding: 0 12px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 5px;
    border: solid 0.5px ${({ theme }) => theme.COLORS.Primary};
    color: ${({ theme }) => theme.COLORS.Primary};
    background-color: transparent;
    transition: all 0.2s ease;

    &:hover {
      color: ${({ theme }) => theme.COLORS.LightWhite};
      background-color: ${({ theme }) => theme.COLORS.Primary};
    }

    &:active {
      transform: scale(0.95);
    }

    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
      border-color: ${({ theme }) => theme.COLORS.LightBlue};
      color: ${({ theme }) => theme.COLORS.LightBlue};
      background-color: ${({ theme }) => theme.COLORS.LightGray};
    }
  }

  .delete-button {
    padding: 0 12px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 5px;
    border: solid 0.5px ${({ theme }) => theme.COLORS.Error};
    color: ${({ theme }) => theme.COLORS.Error};
    background-color: transparent;
    transition: all 0.2s ease;

    &:hover {
      color: ${({ theme }) => theme.COLORS.LightWhite};
      background-color: ${({ theme }) => theme.COLORS.Error};
    }

    &:active {
      transform: scale(0.95);
    }

    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
      border-color: ${({ theme }) => theme.COLORS.LightBlue};
      color: ${({ theme }) => theme.COLORS.LightBlue};
      background-color: ${({ theme }) => theme.COLORS.LightGray};
    }
  }
`;

export const Status = styled.span`
  font-weight: 500;
  text-transform: capitalize;
  color: ${({ color }) => color};
`;
