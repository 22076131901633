export const ACCOUNT_SETTINGS = "account-settings";
export const AI_SETTINGS = "ai-settings";
export const BILLING = "billing";
export const BROADCAST = "broadcast";
export const CAMPAIGN = "campaign";
export const CAMPAIGNS = "campaigns";
export const CATEGORIES = "categories";
export const CHAT = "chat";
export const CHAT_TEMPLATE = "chat-template";
export const CHATROOM = "chatroom";
export const CONNECT = "connect";
export const CONTENT_MANAGEMENT = "content-management";
export const CUSTOMERS_REPORT = "customers-report";
export const DASHBOARD = "dashboard";
export const ENTERPRISE_SETTINGS = "enterprise-settings";
export const FACEBOOK_AUTO_ENGAGEMENT = "facebook-auto-engagement";
export const FACEBOOK_PERSISTENT_MENU = "facebook-persistent-menu";
export const FACEBOOK_LIVE = "facebook-live";
export const FORGOT_PASSWORD = "forgot-password";
export const GENERAL_SETTING = "general-setting";
export const HELP_CENTER = "help-center";
export const HIGHLIGHT_PRODUCT = "highlight-product";
export const IMPERSONATE = "impersonate";
export const INFORMATION = "information";
export const INTEGRATION = "integration";
export const INVENTORY = "inventory";
export const MARKETING_TOOLS = "marketing-tools";
export const MEMBERS = "members";
export const NOTIFICATION_SETTING = "notification-setting";
export const NOTIFICATIONS = "notifications";
export const OFFLINE_STORE = "offline-store";
export const ORDER_REPORT = "order-reports";
export const ORDER_SETTING = "order-setting";
export const ORDERS = "orders";
export const OVERVIEW = "overview";
export const PARCEL_INVOICE_REPORT = "parcel-invoice-reports";
export const PAYMENT = "payment";
export const PLAN = "plan";
export const PLAN_ADDON = "plan-addon";
export const PLAN_UPGRADE = "plan-upgrade";
export const PLAN_USAGE = "plan-usage";
export const PRODUCT_BRAND = "product-brand";
export const PRODUCT_CATEGORY = "product-category";
export const PRODUCT_NAME = "product-name";
export const PRODUCT_PROPERTY = "product-property";
export const PRODUCT_REPORT = "product-reports";
export const PRODUCT_SKU = "product-sku";
export const PRODUCTS = "products";
export const PRODUCTS_INVENTORY = "products-inventory";
export const PROJECT = "project";
export const PROMOTION = "promotion";
export const QUICK_START = "quick-start";
export const REGISTER = "register";
export const REGISTER_WITH_EMAIL = "register-with-email";
export const RESET_PASSWORD = "reset-password";
export const RESPONSE_MESSAGE = "response-message";
export const LINE_RICH_MENU = "line-rich-menu";
export const SALES_REPORT = "sales-reports";
export const SHIPPING = "shipping";
export const SHIPPING_DEFAULT = "shipping-default";
export const SHIPPING_DELIVERY_PERIOD = "delivery-period";
export const SHIPPOP = "shippop";
export const SPECIALIZED_KNOWLEDGE = "specialized-knowledge";
export const STORE_SETTINGS = "store-settings";
export const SYNONYM = "synonym";
export const TERM_AND_CONDITION = "term-and-condition";
export const PRIVACY_POLICY = "privacy-policy";
export const THEME_CONFIG = "theme-config";
export const TOP_SELLING = "top-selling";
export const UP_SELLING = "up-selling";
export const USAGE = "usage";
export const USER_SETTING = "user-setting";
export const VAT = "vat";
export const VERIFY_EMAIL = "verify-email";
export const WELCOME_MESSAGE = "welcome-message";
export const DEEPLE_PAY = "deeple-pay";
export const PAYMENT_CHANNEL = "payment-channel";
export const TRANSACTION = "transaction";
export const DEEPLE_PAY_FEE_INVOICE = "deeple-pay-invoice";
export const AUTO_ENGAGEMENT = "auto-engagement";
export const INSTAGRAM_PERSISTENT_MENU = "instagram-persistent-menu";
export const COD = "cod";
export const PAYMENTS = "payment-method";
export const LINE_OA = "line-oa";
export const FACEBOOK = "facebook";
export const NEW_RECURRING_REQUEST = "new-recurring-request";
export const EDIT_RECURRING_REQUEST = "edit-recurring-request";
export const START_PAGE = "start-page";
export const PAYMENT_METHOD = "payment-method";
export const AUTO_VERIFY_PAYMENT = "auto-verify-payment";

export const PAGE_NAME_INDEX_ON_PATHNAME = 4;
